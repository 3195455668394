<template>
    <div class="SurplusDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="补货机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <!--                        <el-col :span="8">-->
                        <!--                            <el-form-item label="创建人">-->
                        <!--                                <el-input v-model="form.creator" readonly />-->
                        <!--                            </el-form-item>-->
                        <!--                        </el-col>-->
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button type="primary" @click="handleAudit" v-if="form.reviewStatus == 0 ? false : true" size="small"
                    >审核详情</el-button
                >
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
                <PickupReturnAudit
                    ref="PickupReturnAudit"
                    :review-privilege-flag="'menu.mall.manage.repay.goods.review'"
                />
            </div>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.details"
                v-loading="tableLoading"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column label="条码">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.bars.map((d) => d).join(' , ') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | goodsType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.fastBar }}
                    </template>
                </el-table-column>
                <el-table-column label="自编码" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.customBar }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.sku.specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="80" />
                <el-table-column prop="repayGoodsCount" label="实际补货数量" width="120" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'ReplenishmentDetail',
    components: { PickupReturnAudit },
    data() {
        return {
            baseUrl: this.$efApi.replenishmentApi.baseUrl,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                abstractCode: '',
                abstractName: '',
                remark: '',
                createTime: '',
                creator: '',
                details: [],
            },
            audit: {
                code: this.code,
                bizCode: '',
                auditDate: Util.nowFormatDate(),
                advice: '',
                remark: '',
            },
            goodsDetailMap: new Map(),
            tableLoading: true,
            //id标识
            formId: 'form',
            tableId: 'table',
            codeMap: [],
            url: {
                queryGoods: '/goods/goods/list_c',
            },
        };
    },
    mounted() {
        this.code = this.$router.history.current.params.code;
        const _this = this;
        let dataArr = [];
        UrlUtils.QueryRemote(this, 'repayDeptGoods/bizDetail/' + this.code, (rst) => {
            this.form.deptName = rst.deptName;
            this.form.creator = rst.creator;
            this.form.createTime = rst.createTime;
            this.form.remark = rst.createTime;
            dataArr = rst.details;
            dataArr.forEach((v, i) => {
                _this.codeMap.push(v.goodsCode);
            });
        });

        setTimeout(() => {
            if (this.codeMap.length) {
                UrlUtils.QueryGoodsInfoByCodes(this, this.url.queryGoods, { codes: this.codeMap }, (data) => {
                    data.forEach((v, i) => {
                        dataArr.forEach((item, idx) => {
                            if (item.goodsCode === v.code) {
                                item.sku = v.sku;
                                item.fastBar = v.fastBar;
                                item.customBar = v.customBar;
                            }
                        });
                        this.form.details = dataArr;
                        this.tableLoading = false;
                    });
                });
            }
        }, 350);

        this.tableLoading = false;
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        detailParamPropertyArrForShow() {
            return [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('wholeSalePrices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl, this.form.stockStatus);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        type(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
